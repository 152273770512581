<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from "moment";
import CalendarEventModal from "@components/global/CalendarEventModal";


export default {
        page: {title: 'Ημερολόγιο'},
        components: {FullCalendar, CalendarEventModal},

        data() {
            return {
                selectedDate: null,
                localEvents: [],
                calendarOptions: {
                    headerToolbar: {
                        center: 'dayGridMonth,timeGridWeek,timeGrid' // buttons for switching between views
                    },
                    plugins: [ dayGridPlugin, interactionPlugin, bootstrapPlugin, timeGridPlugin],
                    initialView: window.innerWidth >= 765 ? 'timeGridWeek' : 'timeGrid',
                    locale: 'el',
                    editable: true,
                    themeSystem: 'bootstrap',
                    dateClick: this.handleDateClick,
                    eventClick: this.handleEventClick,
                    eventDrop: this.handleEventDrop,
                    eventResize: this.handleEventDrop,
                    datesSet: this.handleViewChange,
                     // aspectRatio: 2,
                    events: [],
                    buttonText: {
                        today:    this.$t('today'),
                        month:     this.$t('month'),
                        week:      this.$t('week'),
                        day:       this.$t('day'),
                        list:     'list',
                        timeGrid:  this.$t('timeGrid')
                    },
                    allDaySlot: false,
                    expandRows: true,
                    nowIndicator: true,
                    firstDay: 1,
                  slotDuration: '00:15:00',
                  slotLabelInterval: 15,
                    weekends: true,
                    slotMinTime: '06:00',
                  slotLabelFormat:{
                      hour: 'numeric',
                      minute: '2-digit',
                      hour12: false
                  },
                  scrollTime:  moment().format("HH:mm:ss")
                },
                modalEvent: {},
                activeView: 'timeGridWeek',
                query:{
                  from: null,
                  to: null
                },
                localDbEvents: [],
              athletes: [],
              googleCalendar: null

            }
        },
      created(){
        this.fetchAthletes();
        this.fetchUserCalendarConnection();
      },
        methods:{

           fetchUserCalendarConnection(){
             this.$axios.get('/calendar/google/status').then(res=>{
               this.googleCalendar = res.data;
               if(!res.data){
                 this.googleCalendar ={
                    pairing: false
                 }
               }else{
                 this.fetchGoogleCalendarEvents();
               }
             }).catch(e => {
               this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η κατάσταση του Google Calendar δεν φορτώθηκε σωστά.'});

             })
           },
            authenticateGoogle(){

              this.$axios.post('/calendar/google/authorize').then(res => {
                window.location.href = res.data;
              }).catch(()=>{
                this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Κάτι πήγε λάθος. Δοκιμάστε ξανά.'});

              });
            },
            deleteGoogleSync(){
              this.$axios.delete('/calendar/google/authorize').then(res => {
                this.$notify({group: 'athlisis-notifications', type: 'success', title: this.$t('success.title'), text: 'Ο συγχρονισμός σταμάτησε επιτυχώς!'});
                this.fetchUserCalendarConnection();
                this.removeGoogleEvents();
              }).catch(()=>{
                this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η διακοπή συγχρονισμού απέτυχε. Παρακαλώ δοκιμάστε ξανά.'});

              });
            },
            fetchAthletes: async function(){
              if(this.athletes.length < 1){
                this.$axios.get(`/athlete/athlete-names`).then(result => {
                  this.athletes = result.data.athletes.rows;
                  this.athletes.map(x =>{
                    x.item_data = x.firstName + ' ' + x.lastName;
                    return x;
                  });
                }).catch(e =>{
                  this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η φόρτωση των αθλητών απέτυχε. Δοκιμάστε ξανά!'});
                });
              }
            },

            fetchEvents(){
              this.$axios.get(`/calendar`, {params: {from: this.query.from, to: this.query.to}}).then(res => {

                for(let event of res.data){
                  this.pushToLocalDbList(event); // hold event in local db objects!
                  this.pushToEventList(this.createCalendarObjectFromDatabase(event)); // add to calendar!
                }
              }).catch(e=>{
                this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'),
                  text: 'Η φόρτωση του ημερολογίου απέτυχε. Παρακαλώ δοκιμάστε ξανά.'});
              })
            },

            fetchGoogleCalendarEvents(){
              this.$axios.get(`/calendar/google`, {params: {from: this.query.from, to: this.query.to}}).then(res => {
                for(let event of res.data){
                  // exclude events that are managed by local state.
                  if(this.localDbEvents.find(x=> x.id.replace(/-/g, '') === event.id)) continue;
                  this.pushToEventList(this.createCalendarObjectFromGoogle(event)); // add to calendar!
                }
              }).catch(e=>{
                this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'),
                  text: 'Η φόρτωση του ημερολογίου απέτυχε. Παρακαλώ δοκιμάστε ξανά.'});
              })
            },

            deleteEventFromLists(id){
              let localIndex = this.localDbEvents.findIndex(x => {return x.id === id});
              let calendarIndex = this.calendarOptions.events.findIndex(x => {return x.id === id});

              if(localIndex > -1){
                this.localDbEvents.splice(localIndex, 1);
              }
              if(calendarIndex > -1){
                this.calendarOptions.events = this.calendarOptions.events.filter( x => x.id !== id);
              }

            },

          removeGoogleEvents(){
             this.calendarOptions.events = this.calendarOptions.events.filter(x => x.source !== 'google');
          },

            pushToLocalDbList(event){
              let existingOne = this.localDbEvents.findIndex(x => {return x.id === event.id});
              if(existingOne !== -1){
                this.localDbEvents[existingOne] = event;
              }else{
                this.localDbEvents.push(event);
              }
            },

            pushToEventList(event){
                let existingOne = this.calendarOptions.events.findIndex(x => {return x.id === event.id});
                if(existingOne !== -1){
                  this.calendarOptions.events[existingOne] = event;
                }else{
                  this.calendarOptions.events.push(event);
                }
            },

            handleViewChange(){
              let hasChanged = false;
              let view = this.$refs.fullCalendar.getApi().view;
              this.activeView = view.type;

              if(this.query.from !== moment(view.currentStart).format('YYYY-MM-DD')){
                this.query.from = moment(view.currentStart).format('YYYY-MM-DD');
                hasChanged = true;
              }

              if(this.query.to !== moment(view.currentEnd).format('YYYY-MM-DD')){
                this.query.to =moment(view.currentEnd).format('YYYY-MM-DD');
                hasChanged = true;
              }

              if(hasChanged){
                this.fetchEvents();
                if(this.googleCalendar && this.googleCalendar.pairing) this.fetchGoogleCalendarEvents();
              }
            },

            handleDateClick: async function(arg) {
                this.modalEvent = Object.assign({}, this.newEvent); // clear modalEvent object
                this.modalEvent.startTime = new Date(arg.dateStr);

                this.modalEvent.date = moment(arg.dateStr);

                if(arg.dateStr.length <11){
                  this.modalEvent.startTime = moment().format('HH:mm');
                  this.modalEvent.endTime = moment().add(this.modalEvent.duration, 'minutes').format('HH:mm');
                }else{
                  this.modalEvent.startTime = moment(arg.dateStr).format('HH:mm');
                  this.updateModalEventEndTime()
                }

                this.$refs['add-event'].show();
            },

            handleEventDrop({event}){
                let localEvent = this.localDbEvents.find(x => x.id === event.id);

                if(!localEvent){
                  this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'),
                    text: this.$t('components.calendar.googleNoEdit')});

                  return;
                }
                let updates = {
                  date: moment(event.start).format('YYYY-MM-DD'),
                  startTime: moment(event.start).format('HH:mm'),
                  endTime: moment(event.end).format('HH:mm')
                }
                const updated = {...localEvent, ...updates};

              this.$axios.put(`/calendar/${localEvent.id}`, updated).then(res => {
                this.deleteEventFromLists(res.data.id);
                this.pushToLocalDbList(res.data);
                this.pushToEventList(this.createCalendarObjectFromDatabase(res.data));
                this.closeModal();
              }).catch(e => {
                this.deleteEventFromLists(localEvent.id);
                this.pushToLocalDbList(localEvent);
                this.pushToEventList(this.createCalendarObjectFromDatabase(localEvent));
                this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'),
                  text: 'Η αλλαγή του συμβάντος απέτυχε. Παρακαλώ δοκιμάστε ξανά.'});
              });

            },

            handleEventClick({event}){
                this.modalEvent = this.localDbEvents.find(x => {
                  return x.id === event.id
                });
                if(!this.modalEvent){
                  this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'),
                    text: this.$t('components.calendar.googleNoEdit')});

                  return;
                }
                this.modalEvent.selectedAthlete = this.modalEvent.athleteId;
                this.$refs['add-event'].show();
            },

            addEventModal: async function(){
                this.modalEvent = Object.assign({},this.newEvent);
                this.modalEvent.date = moment();
                this.modalEvent.startTime = moment().format('HH:mm');
                this.modalEvent.endTime = moment().add(this.modalEvent.duration, 'minutes').format('HH:mm');
                this.$refs['add-event'].show();
            },

            eventDeleted(){
              this.deleteEventFromLists(this.modalEvent.id);
              this.closeModal();
            },
            closeModal(){
              this.modalEvent = null;
              this.$refs['add-event'].hide();
              this.modalEvent = Object.assign({}, this.newEvent); // clear modalEvent object

            },

            eventCreated(res){
              if(res.isUpdate) this.deleteEventFromLists(res.data.id);
              this.pushToLocalDbList(res.data);
              this.pushToEventList(this.createCalendarObjectFromDatabase(res.data));
              this.closeModal();
            },

          createCalendarObjectFromDatabase(event){
              return {
                id: event.id,
                title: event.title,
                date: event.date,
                backgroundColor: event.isCancelled? '#721c24' : (parseInt(event.type) === 0 ? '#c3e6cb' : ''),
                textColor: parseInt(event.type) === 0 ? '#155724' : '',
                notes: event.notes,
                start: moment(event.date).set('hour', event.startTime.substring(0, 2)).set('minute', event.startTime.substring(3, 5)).toISOString(),
                end: moment(event.date).set('hour', event.endTime.substring(0, 2)).set('minute', event.endTime.substring(3, 5)).toISOString()
              }
          },

          createCalendarObjectFromGoogle(event){
             return {
               id: event.id,
               title: event.summary,
               date: moment(event.start.dateTime).format('YYYY-MM-DD'),
               start: moment(event.start.dateTime).toISOString(),
               end: moment(event.end.dateTime).toISOString(),
               backgroundColor:'#039be5',
               editable: false,
               source: 'google'
             }
          },

          calendarObjectToDatabase(event){
              this.calendarOptions.events.find(x => {return x.id === id});
          },
          updateModalEventEndTime(){
            this.modalEvent.endTime = moment().set("hour", this.modalEvent.startTime.substring(0,2)).set("minute", this.modalEvent.startTime.substring(3,5)).add(this.modalEvent.duration, 'minutes').format('HH:mm');
          },

          timeToFormat(time){
              return moment(time).format('HH:mm');
          },

          cancelledStatusToggled(res){
            this.deleteEventFromLists(res.data.id);
            this.pushToLocalDbList(res.data);
            this.pushToEventList(this.createCalendarObjectFromDatabase(res.data));
            this.closeModal();
          }
        }
    }
</script>
<style lang="scss">
   .fc-day-today {     background: $color_light_bg !important; }
    #calendar{

        .fc-dayGridMonth-view{
          .fc-event{
            margin-top:0.4em;
            color:#fff;
          }
        }
        .fc-toolbar h2{
            text-transform:initial;
        }
        .floating-btn{
            font-size: 20px;
            position:fixed;
            right: 30px;
            bottom:100px;
            padding:10px 15px;
            border-radius: 100px;
            box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
            z-index:999;
        }

      .fc-event{
        border-radius:9px!important;
        padding:.3em 1em!important;
        background-color:$color_primary;
        border:0!important;

        @media(max-width:1400px){

          margin:0!important;

          white-space: nowrap;
          overflow:hidden;
        }


      }
      .table-bordered thead th{
        border-bottom-width:1px!important;
      }
      .fc-timegrid-event .fc-event-main{
        padding:0!important
      }

      .calendar-dot{
        height: 8px;
        width: 8px;
        background-color: $color_primary;
        border-radius: 50%;
        margin: 0 4px;
        display: inline-block;
      }

      @media (max-width: 720px){
            .fc-header-toolbar{
                display:block;
                text-align:center;
                .fc-toolbar-chunk{
                    margin:5px;
                }

            }
            .floating-btn{
                right:0;
                bottom:50px;
            }
            .fc-view-harness{
                height:550px!important;
            }
            .calendar-row-wrapper{
                padding:0!important;
            }
        }
    }

</style>

<template>

<div id="calendar" class="row">
          <div v-if="googleCalendar" class="google-calendar col-12 text-right">
            <p v-if="googleCalendar.pairing && !googleCalendar.expired" class="mb-0">
              <b-button variant="danger" size="xs" class="mb-2" @click="deleteGoogleSync">    {{ $t('components.calendar.stop') }} </b-button><br>
              {{ $t('components.calendar.activeSync') }} <span class="text-primary font-weight-bold">{{ googleCalendar.email }}</span><br>
              <span style="font-size:0.8rem;"> {{ $t('components.calendar.notFunctioning') }}
                <span class="text-primary font-weight-bold" style="cursor:pointer;" @click="authenticateGoogle">{{$t('click')}}</span></span><br>
            </p>

            <p v-if="googleCalendar.pairing && googleCalendar.expired">
              {{$t('components.calendar.syncPaused')}}
            </p>
            <b-button v-if="!googleCalendar.pairing || googleCalendar.expired" variant="outline-primary" @click="authenticateGoogle" :disabled="!!isEducational">

              <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"><defs><path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/></defs><clipPath id="b"><use xlink:href="#a" overflow="visible"/></clipPath><path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"/><path clip-path="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/><path clip-path="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/><path clip-path="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"/></svg>

              {{ $t('components.calendar.sync') }} <b-badge v-if="!!isEducational" variant="success">Μη διαθέσιμο στο educational</b-badge></b-button>
          </div>

          <div class="col-md-12">
            <FullCalendar ref="fullCalendar" :options="calendarOptions">
              <template v-slot:eventContent='arg'>
                <b v-if="activeView === 'dayGridMonth'"> <span class="calendar-dot"
                                                               :style="arg.backgroundColor ? 'background-color:' + arg.backgroundColor : ''"></span>
                  {{timeToFormat(arg.event.start) + ': '}}</b>

                <span :style="arg.backgroundColor === '#721c24' ? 'text-decoration: line-through': ''">

                  {{ arg.event.title }}
                  <i v-if="arg.event.extendedProps && arg.event.extendedProps.notes" class="fas fa-comment" style="font-size:0.8em"/>
                </span>
              </template>
            </FullCalendar>
          </div>

        <b-modal id="add-event" ref="add-event" :click-to-close="false" hide-footer body-class="p-3" >
          <template #modal-title>
            <span v-if="modalEvent.id">
              {{ $t('components.calendar.edit') }}
               <b-badge v-if="modalEvent.isCancelled" size="xs" variant="danger"> {{ $t('components.calendar.cancelled') }}</b-badge>
            </span>
            <span v-else>
               {{ $t('components.calendar.add') }}
            </span>
          </template>
          <calendar-event-modal v-if="modalEvent" :modal-event="modalEvent" :athletes="athletes"
                                @eventUpdated="eventCreated" @eventDeleted="eventDeleted"
                                @cancelledStatusToggled="cancelledStatusToggled"/>
        </b-modal>

        <b-button variant="primary" class="floating-btn" @click="addEventModal"><i class="fe-plus"></i></b-button>

    </div>


</template>
